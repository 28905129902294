import React, { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/commons";
import Seo from "../components/layout/seo";
import ImageComponent from "../components/utils/image";
import { LanguageContext } from "../components/context/language-context";

const Article = ({ data, pageContext }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);

  const [ai] = story?.content?.article_information;
  const { immagine_hero, titolo, abstract, descrizione } = ai;

  const [ih] = immagine_hero ?? [];
  const [cat] = (story?.tag_list ?? []).filter((t) => t.startsWith("c-"));
  const dataString =
    (ai?.data && new Date(ai?.data).toLocaleDateString("it")) ||
    story.published_at;

  const { t } = useContext(LanguageContext);

  return (
    <Layout languageMappings={pageContext?.lm ?? {}}>
      <Seo
        info={story?.content?.article_information[0]}
        lm={pageContext?.lm ?? {}}
        isArticle={true}
        lang={story?.lang}
      />
      {/* <div
        style={{
          background:
            "linear-gradient(90.87deg, #A71970 -1.01%, #D72E38 52.32%, #F49235 108.46%)",
        }}
        className="h-5 lg:h-11"
      /> */}
      {ih && <ImageComponent img={ih} />}
      <div className="ws-cont px-8 flex justify-end">
        <p className="text-sm text-ws-purple uppercase py-0.5">
          {t[cat] || cat.slice(2).replaceAll("-", " ")}
          <span className="font-bold pl-2">{dataString}</span>
        </p>
      </div>
      <div className="bg-ws-lightgray py-[26px]">
        <div className="ws-cont px-8">
          <h1 className="text-center ws-h1">{titolo}</h1>
          {(abstract || descrizione) && (
            <p className="text-2xl font-bold text-center max-w-4xl mx-auto px-4 pb-5 border-b border-black">
              {abstract || descrizione}
            </p>
          )}
          <div className="flexc justify-center gap-2.5 flex-wrap max-w-4xl mx-auto px-4 mt-5">
            {(story?.tag_list ?? [])
              .filter((t) => t.startsWith("t-"))
              .map((tag) => (
                <div
                  key={tag}
                  className="border-2 border-black py-2 px-4 font-bold"
                >
                  {t[tag] || tag.slice(2).replaceAll("-", " ")}
                </div>
              ))}
          </div>
        </div>
      </div>
      {(story?.content?.content ?? []).map(getBlok)}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $images: [String]!) {
    storyblokEntry(id: { eq: $id }) {
      field_component
      content
      lang
      name
      slug
      tag_list
      published_at(formatString: "d/M/yy")
    }
    images: allFile(filter: { base: { in: $images } }) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Article;
